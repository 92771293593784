/* global BigInt */
import React, { Fragment } from 'react';
import Countdown from 'src/components/Countdown';
import { withContext } from 'src/context';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import { ContractData, ContractForm } from '@drizzle/react-components';
import { withDrizzle } from 'src/withDrizzle';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Statistics from 'src/components/Statistics';
import Invoices from 'src/components/Invoices';
import UserContribution from 'src/components/UserContribution';
import Contribute from 'src/components/Contribute';
import Balance from 'src/components/Balance';
import Survey from 'src/components/Survey';
import SaleProgress from 'src/components/SaleProgress';
import StickyBar from 'src/components/StickyBar';

const moment = require('moment');

const styles = theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  disableClick: {
    pointerEvents: 'none'
  },
  balances: {
    display: 'flex'
  },
  balance: {
    width: '50%'
  },
  module: {
    padding: '20px 0px'
  }
});

const weiToEth = (number, decimals) => {
  if (isNaN(number) && isNaN(Number(number))) return 0;
  return (
    Math.floor(number / Math.pow(10, 18 - decimals)) / Math.pow(10, decimals)
  );
};

class Contributions extends React.Component {
  // For convenience
  constructor(props, context) {
    super(props);
    this.state = {
      paramKeysToSync: { MethaCrowdsale: {}, MethaToken: {} },
      syncParams: { MethaCrowdsale: {}, MethaToken: {} },
      currentPeriodContributorsData: [],
      paymentAmount: 0,
      methaBalance: 0,
      networkVersion: -1,
      init: false,
      openTransactionId: null
    };
  }

  async componentDidMount() {
    const { initialized, contracts } = this.props;
    if (!initialized || contracts.length < 1) return;
    else this.initializeDappInteraction();
  }

  /*   componentWillUnmount() {
      clearInterval(this.interval);
    } */

  initializeDappInteraction = async () => {
    if (this.state.init) return;

    this.setState({ init: true });

    const { userData, onUpdateUserWallet } = this.props;

    const accounts = this.props.drizzleState.accounts;

    this.setState({
      networkVersion: this.props.drizzle.web3.currentProvider.networkVersion,
      selectedAddress: this.props.drizzle.web3.currentProvider.selectedAddress
    });

    this.props.drizzle.web3.currentProvider.publicConfigStore.on(
      'update',
      input => {
        if (this.state.networkVersion != -1) {
          if (
            input.networkVersion &&
            this.state.networkVersion != input.networkVersion
          ) {
            return this.initializeDappInteraction();
          }
          if (
            input.selectedAddress &&
            this.state.selectedAddress != input.selectedAddress
          ) {
            this.props.reRender();
          }
        }
      }
    );

    if (userData && userData.wallet !== accounts[0]) {
      onUpdateUserWallet(accounts[0]);
    }

    this._addParamsToSync(
      [
        ['getLastSnapshotId'],
        ['getDeveloperStash', ['0x436F3EE2c47299054662dE9AA7178dfB51EB67DD']],
        ['getOperationStash'],
        ['getTokensaleStats'],
        ['getRemainingPeriodTime'],
        ['getAccountCurrentPeriodContribution', [accounts[0]]],
        ['getAccountClaimableTokens', [accounts[0]]],
        ['getAccountTotalContribution', [accounts[0]]],
        ['getTokensCreatedPerPeriod'],
        ['getCurrentPeriod'],
        ['getStartTimeOfNextPeriod'],
        ['getWeiRaised'],
        ['getAvgWeiRaisedPerPeriod'],
        ['getWeiRaisedPerPeriod', [0]],
        ['getContributorsLengthPerPeriod', [0]]
      ],
      'MethaCrowdsale'
    );

    this._addParamsToSync(
      [
        ['balanceOf', [accounts[0]]],
        ['balanceOfAt', [accounts[0], 2]]
      ],
      'MethaToken'
    );

    /* this.interval = setInterval(() => {
      this.refreshParams();
    }, 3000); */
  };

  _addParamsToSync = (params, contract) => {
    const { paramKeysToSync } = this.state;

    var newParamKeysToSync = { ...paramKeysToSync };

    //Init contract obj if doesn't exist
    if (typeof newParamKeysToSync[contract] !== 'object')
      newParamKeysToSync[contract] = {};

    params.forEach(param => {
      let key,
        args = param[1];
      if (args)
        key = this.props.contracts[contract].methods[param[0]].cacheCall(
          ...args
        );
      else key = this.props.contracts[contract].methods[param[0]].cacheCall();
      newParamKeysToSync[contract][param[0]] = key;
    });

    return this.setState({
      paramKeysToSync: newParamKeysToSync
    });
  };

  refreshParams = () => {
    const accounts = this.props.drizzleState.accounts;
    console.log('refresh');
    this._addParamsToSync(
      [
        ['getDeveloperStash', ['0x436F3EE2c47299054662dE9AA7178dfB51EB67DD']],
        ['getOperationStash'],
        ['getTokensaleStats'],
        ['getRemainingPeriodTime'],
        ['getAccountCurrentPeriodContribution', [accounts[0]]],
        ['getAccountClaimableTokens', [accounts[0]]],
        ['getAccountTotalContribution', [accounts[0]]],
        ['getTokensCreatedPerPeriod'],
        ['getCurrentPeriod'],
        ['getStartTimeOfNextPeriod'],
        ['getWeiRaised'],
        ['getAvgWeiRaisedPerPeriod'],
        ['getWeiRaisedPerPeriod', [0]],
        ['getContributorsLengthPerPeriod', [0]]
      ],
      'MethaCrowdsale'
    );

    this._addParamsToSync([['balanceOf', [accounts[0]]]], 'MethaToken');
  };

  addTokenMetamask = () => {
    var getUrl = window.location;
    var baseUrl = getUrl.protocol + '//' + getUrl.host;
    if (!window.ethereum || !window.ethereum.sendAsync) {
      return toast.error(
        'Metamask browser extensions not found or out of date.',
        {
          position: toast.POSITION.TOP_RIGHT
        }
      );
    }
    window.ethereum.sendAsync(
      {
        method: 'metamask_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: this.props.dappState.methaTokenAddress, // The address that the token is at.
            symbol: 'MET', // A ticker symbol or shorthand, up to 5 chars.
            decimals: 18, // The number of decimals in the token
            image: `${baseUrl}/static/images/logos/icon.png` // A string url of the token logo
          }
        },
        id: Math.round(Math.random() * 100000)
      },
      (err, addedBoolean) => {}
    );
  };

  getDappValues = contract => {
    const { paramKeysToSync, init } = this.state;
    const { drizzleState, initialized, dappState } = this.props;

    if (!initialized || !init) return dappState;
    var values = {};

    Object.entries(paramKeysToSync[contract]).forEach(([key, value]) => {
      if (!drizzleState.contracts[contract][key][value]) return null;
      values[key] = drizzleState.contracts[contract][key][value].value;
    });
    return values;
  };

  getDappValue = (name, contract) => {
    const { paramKeysToSync } = this.state;
    const { drizzleState, initialized } = this.props;
    if (!initialized) return null;
    if (
      !paramKeysToSync[contract][name] ||
      !drizzleState.contracts[contract][name][paramKeysToSync[contract][name]]
    )
      return null;
    return drizzleState.contracts[contract][name][
      paramKeysToSync[contract][name]
    ].value;
  };

  getAccountBalance = async () => {
    const { initialized } = this.props;
    if (!initialized) return 0;
    //Get account metha balance each time there is an update
    const balance = await this.props.contracts.MethaToken.methods
      .balanceOf(this.props.drizzleState.accounts[0])
      .call();
    this.props.onSetGlobalMethaBalance(weiToEth(balance, 2));
    return weiToEth(Number(balance), 2);
  };

  getContributorsData = async () => {
    var l = this.getDappValue(
      'getContributorsLengthPerPeriod',
      'MethaCrowdsale'
    );
    var currentPeriodContributorsData = await this.getCurrentPeriodContributorsData(
      l
    );
    return currentPeriodContributorsData;
  };

  getContractData = contractName => {
    var output = {};
    var keys = Object.keys(this.state.paramKeysToSync[contractName]);
    keys.forEach(key => {
      if (this.state.syncParams && this.state.syncParams[contractName]) {
        output[key] = this.state.syncParams[contractName][key];
      } else {
        output[key] = null;
      }
    });
    return output;
  };

  getCurrentPeriodContributorsData = async length => {
    var currentPeriodContributorsData = [];
    for (var i = 0; i < length; i++) {
      const data = await this.props.contracts.MethaCrowdsale.methods
        .getContributorsPeriodIndex(0, i)
        .call();
      currentPeriodContributorsData.push(data);
    }
    currentPeriodContributorsData = currentPeriodContributorsData.map(el => {
      return {
        address: el[0],
        contribution: weiToEth(el[2], 2),
        time: new Date(Number(el[3]) * 1000)
      };
    });
    return currentPeriodContributorsData;
  };

  async componentDidUpdate(prevProps, prevState) {
    /*     console.log("UPDATE")
        const { drizzleState } = this.props;
        if (drizzleState && drizzleState.contracts && drizzleState.contracts.MethaCrowdsale) {
          console.log(JSON.stringify(drizzleState.contracts.MethaCrowdsale.getRemainingPeriodTime, null, 0))
        } */

    if (
      !prevProps.initialized &&
      this.props.initialized &&
      this.props.drizzle &&
      Object.keys(this.props.drizzle.contracts).length > 1
    ) {
      return this.initializeDappInteraction();
    }
    if (prevProps.initialized && !this.props.initialized) {
      return;
    }
    if (
      this.props.initialized &&
      !this.state.init &&
      Object.keys(this.props.drizzle.contracts).length > 1
    ) {
      return this.initializeDappInteraction();
    }
  }

  onChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleContribution = (paymentAmount, period) => {
    const { initialized, popUp, loadingMessage } = this.props;

    if (
      !this.props.initialized ||
      !this.props.withDrizzle ||
      !this.state.init
    ) {
      toast.error('Disconnected. Use the Connect button in Top Bar.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    const accounts = this.props.drizzleState.accounts;
    const currentPeriod = this.getDappValue(
      'getCurrentPeriod',
      'MethaCrowdsale'
    );

    if (paymentAmount < 0.01)
      return toast.error('The minimum contribution is 0.01 ETH.', {
        position: toast.POSITION.TOP_RIGHT
      });
    if (period.length == 0)
      return toast.error('No period selected to contribute.', {
        position: toast.POSITION.TOP_RIGHT
      });
    var periodList = period.map(el => Number(currentPeriod) + el);

    // periodList.forEach(el => this.props.contracts.MethaCrowdsale.methods.contribute.cacheSend(0, { value: String(BigInt(paymentAmount * Math.pow(10, 18))), from: accounts[0] }));
    const stackId = this.props.contracts.MethaCrowdsale.methods.contribute.cacheSend(
      0,
      {
        value: String(BigInt(paymentAmount * Math.pow(10, 18))),
        from: accounts[0]
      }
    );

    this.setState({ openTransactionId: stackId });
    // Use the dataKey to display the transaction status.
    /*     if (this.props.drizzleState.transactionStack[stackId]) {
          const txHash = state.transactionStack[stackId]
    
          this.setState() state.transactions[txHash].status
        } */
    return true;
  };

  handleClaimTokens = async () => {
    const { initialized, popUp, loadingMessage } = this.props;
    if (
      !this.props.initialized ||
      !this.props.withDrizzle ||
      !this.state.init
    ) {
      toast.error('Disconnected. Use the Connect button in Top Bar.', {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    var periodsToClaim = await this.getPeriodsToClaim();
    if (periodsToClaim.length == 0) {
      toast.error(
        "You don't have Infra Tokens to claim. Contribute to the auction first.",
        { position: toast.POSITION.TOP_RIGHT }
      );
      return false;
    }

    const stackId = this.props.contracts.MethaCrowdsale.methods.claimList.cacheSend(
      periodsToClaim
    );
    this.setState({ openTransactionId: stackId });
    return true;
  };

  getPeriodsToClaim = async () => {
    var periodsToClaim = [];
    const nextPeriod = await this.props.contracts.MethaCrowdsale.methods
      .getAccountNextPeriodWithClaimableTokens(
        this.props.drizzleState.accounts[0],
        0
      )
      .call();
    if (nextPeriod > 0) {
      periodsToClaim.push(nextPeriod);
      var next = await this.props.contracts.MethaCrowdsale.methods
        .getAccountNextPeriodWithClaimableTokens(
          this.props.drizzleState.accounts[0],
          nextPeriod
        )
        .call();
      while (next > 0) {
        periodsToClaim.push(next);
        next = await this.props.contracts.MethaCrowdsale.methods
          .getAccountNextPeriodWithClaimableTokens(
            this.props.drizzleState.accounts[0],
            next
          )
          .call();
      }
      return periodsToClaim;
    } else return periodsToClaim;
  };

  handleRefresh = async () => {
    await this.props.onRefreshData();
  };

  render() {
    const {
      getLastSnapshotId: snap,
      getDeveloperStash: devStash,
      getOperationStash: opStash,
      getCurrentPeriod: currentPeriod,
      getWeiRaisedPerPeriod: periodRaised,
      getTokensCreatedPerPeriod: periodDistribution,
      getAccountClaimableTokens: accountClaimableTokens,
      getAccountTotalContribution: accountTotalContribution,
      getTokensaleStats: _tokenSaleStats,
      getRemainingPeriodTime: remainingPeriodTime,
      getAccountCurrentPeriodContribution: accountCurrentPeriodContribution,
      getAvgWeiRaisedPerPeriod: avgRaisedPerPeriod,
      getContributorsLengthPerPeriod: contributorsLengthPerPeriod
    } = this.getDappValues('MethaCrowdsale');

    const { balanceOfAt } = this.getDappValues('MethaToken');

    const { currentPeriodContributorsData, openTransactionId } = this.state;

    if (this.props.initialized) {
      var test = this.getDappValue(
        'getContributorsLengthPerPeriod',
        'MethaCrowdsale'
      );
    }

    const tokenSaleStats = _tokenSaleStats
      ? Object.keys(_tokenSaleStats).map(e => weiToEth(_tokenSaleStats[e], 2))
      : this.props.tokenSaleStats;

    const { classes, drizzleState } = this.props;

    const lastTransactionStatus =
      openTransactionId != null &&
      drizzleState &&
      drizzleState.transactionStack[openTransactionId]
        ? drizzleState.transactionStack[openTransactionId]
        : undefined;

    if (lastTransactionStatus && lastTransactionStatus.indexOf('TEMP') != 0) {
      this.setState({ openTransactionId: null });
    }

    return (
      <Fragment>
        <div className={classes.root}>
          <div className={`${classes.module}`}>
            <StickyBar
              periodRaised={weiToEth(periodRaised, 2)}
              currentPeriod={currentPeriod}
              periodDistribution={periodDistribution}
              remainingPeriodTime={moment()
                .add(remainingPeriodTime, 'second')
                .toDate()}
            />
          </div>
          <div className={`${classes.module}`}>
            <SaleProgress
              periodRaised={weiToEth(periodRaised, 2)}
              tokenSaleStats={tokenSaleStats}
            />
          </div>
          <div className={`${classes.module}`}>
            <Contribute
              lastTransactionStatus={lastTransactionStatus}
              noMetamask={!this.props.initialized}
              metamaskLoading={this.props.metamaskLoading}
              periodDistribution={periodDistribution}
              avgRaisedPerPeriod={weiToEth(avgRaisedPerPeriod)}
              onContribute={this.handleContribution}
              accountCurrentPeriodContribution={weiToEth(
                accountCurrentPeriodContribution,
                2
              )}
              periodRaised={weiToEth(periodRaised, 2)}
            />
          </div>
          <div className={`${classes.module}`}>
            <Balance
              lastTransactionStatus={lastTransactionStatus}
              onAddTokenToMetamask={this.addTokenMetamask}
              getAccountBalance={this.getAccountBalance}
              init={this.state.init}
              currentPeriod={currentPeriod}
              accountCurrentPeriodContribution={
                accountCurrentPeriodContribution
              }
              onClaimTokens={this.handleClaimTokens}
              accountClaimableTokens={weiToEth(accountClaimableTokens, 2)}
            />
          </div>
          <div>
            <Invoices
              className={classes.module}
              init={this.state.init}
              contributorsLengthPerPeriod={contributorsLengthPerPeriod}
              getContributorsData={this.getContributorsData}
              currentPeriod={currentPeriod}
            />
          </div>
          {/* <div>dev stash por cuenta: {weiToEth(Number(devStash), 3)}MET</div>
          <div>operation stash: {weiToEth(Number(opStash), 3)}MET</div>
          <div>last snapshot id: {snap}</div>

          <div>
            balanceOfAt last snapshot id: {weiToEth(Number(balanceOfAt), 3)}MET
          </div> */}
        </div>
      </Fragment>
    );
  }
}

Contributions.contextTypes = {
  drizzle: PropTypes.object
};

export default withContext(withStyles(styles)(withDrizzle(Contributions)));
