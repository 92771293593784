import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles,
  Button,
  Card
} from '@material-ui/core';
import { useHistory } from 'react-router';
import Countdown from 'src/components/Countdown';
const config = require('src/dapp-config.json');

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 200,
    paddingBottom: 200,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  }
}));

function Hero({ className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const isProd = config.network == 1;

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography variant="overline" color="secondary">
                Introducing
              </Typography>
              <Typography variant="h1" color="textPrimary">
                Welcome to Infra Ecosystem
              </Typography>
              <Box mt={3}>
                <Typography variant="body1" color="textSecondary">
                  Blockchain powered DAO to ensure funding for infrastructure projects.
                </Typography>
              </Box>
              {!isProd && (
                <Box mt={3}>
                  <Grid container spacing={3}>
                    <Grid item>
                      <Typography variant="h1" color="secondary">
                        30K+
                      </Typography>
                      <Typography variant="overline" color="textSecondary">
                        BNB Raised
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h1" color="secondary">
                        5+
                      </Typography>
                      <Typography variant="overline" color="textSecondary">
                        Complete Flows
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h1" color="secondary">
                        30+
                      </Typography>
                      <Typography variant="overline" color="textSecondary">
                        Projects
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              )}
              {isProd && (
                <Card style={{ marginTop: 24 }}>
                  <Box display="flex" flexDirection="column" p={2}>
                    <Box p={1} width="100%">
                      {' '}
                      <Countdown
                        start_time_of_next_period={1593640800000}
                        days
                      />
                    </Box>

                    <Typography
                      variant="h4"
                      color="primary"
                      style={{ margin: 'auto' }}
                    >
                      Until deployment in BSC Mainnet!
                    </Typography>

                    <Box margin="auto" p={2}>
                      <Button
                        color="secondary"
                        component="a"
                        href="https://app.infraecosystem.com"
                        variant="outlined"
                        size="big"
                      >
                        Go to Infra Life Rinkeby Test Env
                      </Button>
                    </Box>
                  </Box>
                </Card>
              )}

              {!isProd && (
                <Box display="flex" flexDirection="column" mt={5}>
                  <Typography
                    variant="h4"
                    color="primary"
                    style={{ margin: 'auto' }}
                  >
                    Discover and test every Infra Ecosystem functionality in
                    test environment.
                  </Typography>

                  <Box pt={2}>
                    <Button
                      color="secondary"
                      variant="contained"
                      size="big"
                      onClick={() => history.push('/dapp')}
                      fullWidth
                    >
                      Get started!
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box position="relative">
              {/*   <div className={classes.shape}>
                <img
                  alt="Shapes"
                  src="/static/home/shapes.svg"
                />
              </div> */}
              {/* <div className={classes.image}>
                <img
                  alt="Presentation"
                  src="/static/home/dark-light.png"
                />
              </div> */}

              <img
                alt="Presentation"
                src="/static/home/welcome-metha.png"
                onClick={() => history.push('/dapp')}
                style={{ maxWidth: '100%', cursor: 'pointer' }}
              />
            </Box>
          </Grid>
        </Grid>
        <Box mt={1} justifyContent="center" textAlign="center">
          <Typography variant="overline" color="textPrimary">
            Do you need more information about Infra Ecosystem?
          </Typography>
          <Button
            variant="text"
            color="primary"
            component="a"
            href="https://infraecosystem.com"
            target="_blank"
          >
            Visit our homepage
          </Button>
        </Box>
      </Container>
    </div>
  );
}

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
